<template>
    <div>
        <slot />
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped></style>